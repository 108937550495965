//theme colors and tints (dark900 to normal500 to light100)
const colors = {
    blue900: "#192c4c",
    blue800: "#2a4a7f",
    blue700: "#3a67b2",
    blue600: "#4b84e5",
    blue500: "#5393fe",
    blue400: "#73abff",
    blue300: "#cbdfff",
    blue200: "#e3efff",
    blue100: "#f1f8ff",

    green600: "#61A840",
    green500: "#6CBB47",
    green200: "#E2F1DA",
    green100: "#F0F8ED",

    grey900: "#111010",
    grey800: "#3D3F42",
    grey700: "#7D7F81",
    grey600: "#A9AAAB",
    grey500: "#BEBFC0",
    grey400: "#D4D4D5",
    grey300: "#E9EAEA",
    grey200: "#F2F2F2",
    grey100: "#F9F9FA",

    orange600: "#DC6039",
    orange500: "#F46B3F",
    orange200: "#FDE1D9",
    orange100: "#FEF0EC",

    pink900: "#804980",
    pink800: "#B366B3",
    pink700: "#CC74CC",
    pink600: "#E683E6",
    pink500: "#FF91FF",
    pink400: "#FFA7FF",
    pink300: "#FFC8FF",
    pink200: "#FFFEFF",
    pink100: "#FFF4FF",

    purple900: "#36254C",
    purple800: "#5B3E80",
    purple700: "#7f56b3",
    purple600: "#A36FE6",
    purple500: "#B57BFF",
    purple400: "#C495FF",
    purple300: "#D3B0FF",
    purple200: "#F0E5FF",
    purple100: "#F8F2FF",

    teal600: "#159f9f",
    teal500: "#17B1B1",
    teal200: "#D1EFEF",
    teal100: "#E8F7F7",

    white: '#FFFFFF',
    mush: '#5E8195',
    navy: '#164057',
    brownE: '#483A4B',
    orange: '#E46B1B',
    darkBrown: '#4F4051',
    lavendar: '#CFADD9',
    grey: '#707070',
    darkGreen: '#4D9C73',
    lightGreen: '#CFF09E'
}

export default colors;
