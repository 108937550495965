exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dashboard-id-js": () => import("./../../../src/pages/dashboard/id.js" /* webpackChunkName: "component---src-pages-dashboard-id-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-governance-addproposal-js": () => import("./../../../src/pages/governance/addproposal.js" /* webpackChunkName: "component---src-pages-governance-addproposal-js" */),
  "component---src-pages-governance-index-js": () => import("./../../../src/pages/governance/index.js" /* webpackChunkName: "component---src-pages-governance-index-js" */),
  "component---src-pages-governance-proposals-js": () => import("./../../../src/pages/governance/proposals.js" /* webpackChunkName: "component---src-pages-governance-proposals-js" */),
  "component---src-pages-governance-vote-1-js": () => import("./../../../src/pages/governance/vote1.js" /* webpackChunkName: "component---src-pages-governance-vote-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

